import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutsComponent } from './shared/layouts/layouts.component';
import { content } from './shared/routes/content.route';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/application/menu/main',
    pathMatch: 'full',
  },
  {
    path: 'application',
    component: LayoutsComponent,
    children: content
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    useHash: true,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
