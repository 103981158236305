import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";

import { HeaderComponent } from './components/header/header.component';
import { LayoutsComponent } from './layouts/layouts.component';

import { ToggleFullscreenDirective } from "./directives/fullscreen.directive";
import { VerticalbarComponent } from './components/verticalbar/verticalbar.component';
import { FooterComponent } from './components/footer/footer.component';
import {LoaderComponent} from './components/loader/loader.component';
import { MenuService } from './service/menu.service';
import { ChoiceService } from './service/choice.service';

@NgModule({
  declarations: [
    ToggleFullscreenDirective,
    HeaderComponent,
    LayoutsComponent,
    VerticalbarComponent,
    LoaderComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  providers: [
    MenuService,
    ChoiceService
  ],
  exports: [LoaderComponent,ToggleFullscreenDirective]
})
export class SharedModule { }
