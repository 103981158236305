<div class="page-main-header">
    <div class="container">
        <div class="modern-header">
            <div class="row">
                <div class="col-4">
                    <div class="header-inner float-sm-left">
                        
                    </div>
                </div>
                <div class="col-4">
                    <div class="header-inner">
                        <div class="logo-wrapper">
                            <a [routerLink]="['/modern-layouts/components/modern']">
                                <img src="assets/images/logo.png" class="img-fluid" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="main-header-right">
                        <div class="vertical-mobile-sidebar">
                            <i class="fa fa-bars sidebar-bar" (click)="switchToggle()"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>