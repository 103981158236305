import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToggleService {
  public show: boolean = false;
  public hide: boolean = true;

  sidebar() {
    this.show = !this.show;
    this.hide = !this.hide;
  }

  constructor() { }
}
