import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  headTitle?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  children?: Menu[];
}

// @Injectable()
@Injectable({
  providedIn: 'root'
})

export class NavService {

  constructor() { }

  MENUITEMS: Menu[] = [
    {
      path: '/modern-layouts/components/modern', title: 'Menu', icon: 'icon-desktop', active: false, type: 'link'
    },
    {
      path: '/modern-layouts/components/modern', title: 'Avis', icon: 'icon-desktop', active: false, type: 'link'
    },
    {
      path: '/modern-layouts/components/modern', title: 'Contact', icon: 'icon-desktop', active: false, type: 'link'
    },
    /*{
      title: 'Layouts', icon: 'icon-layout', type: 'sub', active: false, children: [
        { path: '/modern-layouts/components/page', title: 'Light Sidebar', type: 'link' },
        { path: '/modern-layouts/components/page', title: 'Dark Sidebar', type: 'link' },
        { path: '/modern-layouts/components/page', title: 'Sidebar Fixed', type: 'link' },
      ]
    }, */
    
  ]
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

}
