import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

export var products = [
    {
        id: 1,
        name: "Rolls végétarien",
        price: "5.5",
        description: "Rolls végétariens vendus par 2 <br /> <br /> Les rolls sont des rouleaux croustillants garnis d’un curry à base de carottes, pommes de terre, petit pois et fromage.",
        is_veggy: true,
        img_url: "assets/images/products/rolls.jpg",
        allergens: [
            "Lait et produits à base de lait (y compris de lactose)",
        ]
    },
    {
        id: 2,
        name: "Rolls poulet",
        price: "6",
        description: "Rolls poulet vendus par 2. <br /> <br />Les rolls sont des rouleaux croustillants garnis d’un curry à base de poulet, pommes de terre, petit pois et fromage.<br />Toutes nos viandes sont halal.",
        is_veggy: false,
        img_url: "assets/images/products/rolls.jpg",
        allergens: [
            "Lait et produits à base de lait (y compris de lactose)",
        ]
    },
    {
        id: 2,
        name: "Samoussa legumes",
        price: "5",
        description: "Samoussa legume vendus par 2. <br /> <br />Les samoussas sont des baignets garnis d’un curry à base de pommes de terre, petit pois et carottes.",
        is_veggy: false,
        img_url: "assets/images/products/rolls.jpg",
        allergens: [
            "Aucun",
        ]
    },
    {
        id: 3,
        name: "Rolls boeuf",
        price: "6",
        description: "Rolls boeuf vendus par 2. <br /> <br />Les rolls sont des rouleaux croustillants garnis d’un curry à base de boeuf, pommes de terre, petit pois et fromage.<br />Toutes nos viandes sont halal.",
        is_veggy: false,
        img_url: "assets/images/products/rolls.jpg",
        allergens: [
            "Lait et produits à base de lait (y compris de lactose)",
        ]
    },
    {
        id: 4,
        name: "Kottu bun Végétarien",
        price: "13",
        description: "Le kottu bun est composé de pain sri lankais (le roti) coupés et sautés avec une sauce curry d’aubergines, oeufs et des légumes de saison. Le tout est accompagné de 3 morceaux de rolls végétariens.<br /> <br />On saupoudre le plat de graines de sésames (n’hésitez pas à préciser en commentaire si vous n’en souhaitez pas).",
        is_veggy: false,
        img_url: "assets/images/products/kottubun-veg.jpeg",
        allergens: [
            "Céréales contenant du gluten (blé, seigle, orge, avoine, épeautre, kamut)",
            "Œufs",
            "Lait et lactose",
            "Graines de sésame"
        ]
    },
    {
        id: 5,
        name: "Kottu bun Poulet",
        price: "13.5",
        description: "Le kottu bun est composé de pain sri lankais (le roti) coupés et sautés avec une sauce curry poulet, oeufs et des légumes de saison. Le tout est accompagné de 4 morceaux de rolls poulet.<br /> <br />On saupoudre le plat de graines de sésames (n’hésitez pas à préciser en commentaire si vous n’en souhaitez pas).",
        is_veggy: false,
        img_url: "assets/images/products/kottubun-poulet.jpg",
        allergens: [
            "Céréales contenant du gluten (blé, seigle, orge, avoine, épeautre, kamut)",
            "Œufs",
            "Lait et lactose",
            "Graines de sésame"
        ]
    },
    {
        id: 6,
        name: "Kottu bun Boeuf",
        price: "13.5",
        description: "Le kottu bun est composé de pain sri lankais (le roti) coupés et sautés avec une sauce curry boeuf, oeufs et des légumes de saison. Le tout est accompagné de 4 morceaux de rolls boeuf.<br /> <br />On saupoudre le plat de graines de sésames (n’hésitez pas à préciser en commentaire si vous n’en souhaitez pas).",
        is_veggy: false,
        img_url: "assets/images/products/kottubun-boeuf.jpeg",
        allergens: [
            "Céréales contenant du gluten (blé, seigle, orge, avoine, épeautre, kamut)",
            "Œufs",
            "Lait et lactose",
            "Graines de sésame"
        ]
    },
    {
        id: 7,
        name: "Rotinini Végétarien",
        price: "12",
        description: "Pain sri lankais (le roti) garnis d’un curry d’aubergine, de légumes de saison et de fromage fondu pour plus de gourmandise. Il est accompagné d’une belle salade.",
        is_veggy: false,
        img_url: "assets/images/products/rotinini-poulet.jpeg",
        allergens: [
            "Céréales contenant du gluten (blé, seigle, orge, avoine, épeautre, kamut)",
            "Lait et lactose",
            "Graines de sésame"
        ]
    },
    {
        id: 8,
        name: "Rotinini Poulet",
        price: "12.5",
        description: "Pain sri lankais (le roti) garnis d’un curry de poulet, de légumes de saison et de fromage fondu pour plus de gourmandise. Il est accompagné d’une belle salade.<br /><br />Toutes nos viandes sont halal.",
        is_veggy: false,
        img_url: "assets/images/products/rotinini-poulet.jpeg",
        allergens: [
            "Céréales contenant du gluten (blé, seigle, orge, avoine, épeautre, kamut)",
            "Lait et lactose",
            "Graines de sésame"
        ]
    },
    {
        id: 9,
        name: "Rotinini Boeuf",
        price: "12.5",
        description: "Pain sri lankais (le roti) garnis d’un curry de boeuf, de légumes de saison et de fromage fondu pour plus de gourmandise. Il est accompagné d’une belle salade.<br /><br />Toutes nos viandes sont halal.",
        is_veggy: false,
        img_url: "assets/images/products/rotinini-poulet.jpeg",
        allergens: [
            "Céréales contenant du gluten (blé, seigle, orge, avoine, épeautre, kamut)",
            "Lait et lactose",
            "Graines de sésame"
        ]
    },
    {
        id: 13,
        name: "Muffin au épices",
        price: "4",
        description: "",
        is_veggy: true,
        img_url: "assets/images/products/muffin.jpg"
    },
    {
        id: 14,
        name: "Fluffy Pancakes aux fruits rouges",
        price: "12",
        description: "3 fluffy pancakes accompagnés d'une mascarpone pistache, de framboises fraîches, d'éclats de pistache et de sirop d'érable.",
        is_veggy: true,
        img_url: "assets/images/products/pancake-fruits-rouges.jpg"
    },
    {
        id: 15,
        name: "Samoussa legumes",
        price: "5",
        description: "Samoussa legume vendus par 2. <br /> <br />Les samoussas sont des baignets garnis d’un curry à base de pommes de terre, petit pois et carottes.",
        is_veggy: false,
        img_url: "assets/images/products/samoussa.jpg",
        allergens: [
            "Aucun",
        ]
    },
    {
        id: 16,
        name: "Samoussa poulet",
        price: "5.5",
        description: "Samoussa poulet vendus par 2. <br /> <br />Les samoussas sont des baignets garnis d’un curry à base de poulet, de pommes de terre, petit pois.",
        is_veggy: false,
        img_url: "assets/images/products/samoussa.jpg",
        allergens: [
            "Aucun",
        ]
    },
    {
        id: 17,
        name: "Lanka bowl Végétarien",
        price: "14",
        description: "Riz accompagné d'un curry de courgettes à la creme, d'une salade de carottes, d'un curry d'aubergine et d'une chips Sri lankaise.",
        is_veggy: true,
        img_url: "assets/images/products/lanka-bowl.jpg",
        allergens: [
            "Lait et lactose",
            "Graines de sésame"
        ]
    },
    {
        id: 18,
        name: "Lanka bowl Poulet",
        price: "14.5",
        description: "Riz accompagné d'un curry de courgettes à la creme, d'une salade de carottes, de pilons de poulet au épice et d'une chips Sri lankaise.",
        is_veggy: false,
        img_url: "assets/images/products/lanka-bowl.jpg",
        allergens: [
            "Lait et lactose",
            "Graines de sésame"
        ]
    },
    {
        id: 19,
        name: "Lanka bowl Boeuf",
        price: "14.5",
        description: "Riz accompagné d'un curry de courgettes à la creme, d'une salade de carottes, d'un curry de boeuf et d'une chips Sri lankaise.",
        is_veggy: false,
        img_url: "assets/images/products/lanka-bowl.jpg",
        allergens: [
            "Lait et lactose",
            "Graines de sésame"
        ]
    },
    {
        id: 20,
        name: "Fluffy Pancakes au chocolat",
        price: "12",
        description: "3 fluffy pancakes accompagnés d'une mascarpone chocolat, de bananes, de praliné et de sirop d'érable.",
        is_veggy: true,
        img_url: "assets/images/products/pancake-chocolat.jpg",
        allergens: [
            "Lait et lactose",
        ]
    },
    {
        id: 21,
        name: "Croissant au bacon",
        price: "12.5",
        description: "Croissant garni avec du bacon de boeuf, des oeufs brouillés, des pousses d'épinard et de fromage. Le tout accompagné d'une salade.",
        is_veggy: false,
        img_url: "assets/images/products/croissant-bacon.jpg",
        allergens: [
            "Lait et lactose",
        ]
    },
    {
        id: 22,
        name: "Croissant au saumon",
        price: "12.5",
        description: "Croissant garni avec du saumon fumé, des oeufs brouillés, de l'avocat, de la roquette et de fromage. Le tout accompagné d'une salade.",
        is_veggy: false,
        img_url: "assets/images/products/croissant-saumon.jpg",
        allergens: [
            "Lait et lactose",
        ]
    },
    {
        id: 23,
        name: "Panna cotta",
        price: "5",
        description: "Panna cotta coulis de fruit rouge fait maison.",
        is_veggy: false,
        img_url: "assets/images/products/panna-cotta.jpg",
        allergens: [
            "Lait et lactose",
        ]
    },
    {
        id: 24,
        name: "Glace cookies maison",
        price: "6.5",
        description: "Glace cookies accompagné d'un coulis de chocolat et de praliné.",
        is_veggy: false,
        img_url: "assets/images/products/glace-cookie.jpg",
        allergens: [
            "Lait et lactose",
        ]
    },
    {
        id: 25,
        name: "Gateau au chocolat",
        price: "7.5",
        description: "Gateau au chocolat accompagné d'une boule de glace vanille et de chantilly.",
        is_veggy: false,
        img_url: "assets/images/citron.png",
        allergens: [
            "Lait et lactose",
        ]
    },
    {
        id: 26,
        name: "Gaufres sucre",
        price: "3.5",
        description: "Gaufre avec des perles de sucre <br /><br />Toppings en option possible: Chantilly, Kit kat, Praliné, Granola, Sauce Chocolat au lait ou chocolat blanc.",
        is_veggy: false,
        img_url: "assets/images/products/gaufre.jpg",
        allergens: [
            "Lait et lactose",
        ]
    },
    {
        id: 27,
        name: "Gaufres frangipane",
        price: "4.0",
        description: "Gaufre avec des perles de sucre <br /><br />Toppings en option possible: Chantilly, Kit kat, Praliné, Granola, Sauce Chocolat au lait ou chocolat blanc.",
        is_veggy: false,
        img_url: "assets/images/products/gaufre.jpg",
        allergens: [
            "Lait et lactose",
        ]
    },
    {
        id: 28,
        name: "Café / Déca",
        price: "1.5",
        description: "",
        is_veggy: false,
        img_url: "assets/images/products/cafe.jpg",
        allergens: [
        ]
    },
    {
        id: 29,
        name: "Double",
        price: "2.8",
        description: "",
        is_veggy: false,
        img_url: "assets/images/products/double.jpg",
        allergens: [
        ]
    },
    {
        id: 30,
        name: "Noisette",
        price: "2",
        description: "",
        is_veggy: false,
        img_url: "assets/images/products/cafe.jpg",
        allergens: [
        ]
    },
    {
        id: 31,
        name: "Cappuccino",
        price: "4",
        description: "",
        is_veggy: false,
        img_url: "assets/images/products/cappucino.jpg",
        allergens: [
            "Lait et lactose",
        ]
    },
    {
        id: 32,
        name: "Latte",
        price: "4.5",
        description: "",
        is_veggy: false,
        img_url: "assets/images/products/latte.jpg",
        allergens: [
            "Lait et lactose",
        ]
    },
    {
        id: 33,
        name: "Latte Chocolat blanc",
        price: "5",
        description: "",
        is_veggy: false,
        img_url: "assets/images/products/latte.jpg",
        allergens: [
            "Lait et lactose",
        ]
    },
    {
        id: 34,
        name: "Latte cookie",
        price: "5",
        description: "",
        is_veggy: false,
        img_url: "assets/images/products/latte-cookie.jpg",
        allergens: [
            "Lait et lactose",
        ]
    },
    {
        id: 35,
        name: "Chocolat chaud",
        price: "4",
        description: "",
        is_veggy: false,
        img_url: "assets/images/products/chocolat-chaud.jpg",
        allergens: [
            "Lait et lactose",
        ]
    },
    {
        id: 36,
        name: "Chocolat chaud vienois",
        price: "4.5",
        description: "",
        is_veggy: false,
        img_url: "assets/images/products/chocolat-vienois.jpg",
        allergens: [
            "Lait et lactose",
        ]
    },
    {
        id: 37,
        name: "Thés et infusions",
        price: "3.5",
        description: "Le vent en poupe : Thé vert menthe verte <br/>Rehab : Thé vert Gingembre Citronnelle <br/>Double Jeu : Thé noir Amandes Noix torréfiées <br/>",
        is_veggy: false,
        img_url: "assets/images/citron.png",
        allergens: [
            "Lait et lactose",
        ]
    },
    {
        id: 38,
        name: "AOP St Nicolas de Bourgeuil - Domaine de bois mayaud \"l'osmose\"",
        price: "5",
        description: "Vin souple et fruité <br /> Prix verre : 5€<br />Prix bouteille : 20€",
        is_veggy: false,
        img_url: "assets/images/products/osmos.jpg",
        allergens: [
        ]
    },
    {
        id: 39,
        name: "AOP Côtes du Rhône - Parallèle 45 - Paul Jaboulet Ainé",
        price: "6",
        description: "Vin croquant et élégant aux notes de fruits rouge et épices (BIO)<br /> Prix verre : 6€<br />Prix bouteille : 25€",
        is_veggy: false,
        img_url: "assets/images/products/parallele45.jpg",
        allergens: [
        ]
    },
    {
        id: 40,
        name: "AOP Moulis - Château Dutruch Grand Poujeaux",
        price: "35",
        description: "Vin aux notes de fruits noirs et épices <br /> Prix bouteille : 35€",
        is_veggy: false,
        img_url: "assets/images/products/moulis.jpg",
        allergens: [
        ]
    },
    {
        id: 41,
        name: "IGP OC Chardonnay - Secret de Lunes",
        price: "5",
        description: "Vin fruité, belle minéralité<br /> Prix verre : 5€<br /> Prix bouteille : 20€",
        is_veggy: false,
        img_url: "assets/images/products/chardonnay.jpg",
        allergens: [
        ]
    },
    {
        id: 42,
        name: "AOP Alsace Gewuztrminer - Jean Baptiste Adam",
        price: "6",
        description: "Vin au notes de fruits exotiques <br /> Prix verre : 6€<br /> Prix bouteille : 25€",
        is_veggy: false,
        img_url: "assets/images/products/alsace.jpg",
        allergens: [
        ]
    },
    {
        id: 43,
        name: "AOP Sancerre Pascal Jolivet",
        price: "35",
        description: "Vin aux notes d'agrumes et fleurs blanches <br /> Prix bouteille : 35€",
        is_veggy: false,
        img_url: "assets/images/products/sancerre.jpg",
        allergens: [
        ]
    },
    {
        id: 44,
        name: "IGP Oc Gris Blanc - Gérard Bertrand",
        price: "6",
        description: "Vin cristallin aux notes fraîches et minérales <br /> Prix verre : 6€<br /> Prix bouteille : 25€",
        is_veggy: false,
        img_url: "assets/images/products/rose.jpg",
        allergens: [
        ]
    },
]

export var mainMenuSections = [
    {
        id: 1,
        section: "Notre selection",
        productIds: [16, 18, 13]
    },
    {
        id: 2,
        section: "Entrées",
        productIds: [1, 2, 3, 15, 16]
    },
    {
        id: 3,
        section: "Plats",
        productIds: [4, 5, 6, 7, 8, 9, 17, 18, 19]
    },
    {
        id: 4,
        section: "Desserts",
        productIds: [13, 23, 24, 25]
    }
]

export var brunchSections = [
    {
        id: 1,
        section: "Salé",
        productIds: [21,22]
    },
    {
        id: 2,
        section: "Sucré",
        productIds: [14, 20]
    },
    {
        id: 3,
        section: "Jus",
        productIds: []
    },
    {
        id: 4,
        section: "Boissons chaudes",
        productIds: [28, 29, 30, 31, 32, 33, 34, 35, 36, 37]
    }
]

export var breakSections = [
    {
        id: 1,
        section: "Gaufres",
        productIds: [26, 27]
    },
    {
        id: 2,
        section: "Gateaux / Desserts du jours",
        productIds: [13, 23, 24, 25]
    }
]

export var drinkSections = [
    {
        id: 1,
        section: "Soft",
        productIds: []
    },
    {
        id: 2,
        section: "Boissons chaudes",
        productIds: [28, 29, 30, 31, 32, 33, 34, 35, 36, 37]
    },
    {
        id: 3,
        section: "Vins rouges",
        productIds: [38, 39, 40]
    },
    {
        id: 4,
        section: "Vins blancs",
        productIds: [41, 42, 43]
    },
    {
        id: 5,
        section: "Vin rosé",
        productIds: [44]
    },
    {
        id: 6,
        section: "Bières",
        productIds: []
    }
]


@Injectable()
export class MenuService {

    constructor() { }

    getMain(): Observable<any> {
        mainMenuSections.forEach(section => {
            section['products'] = section.productIds.map(productId => {
                return products.find(product => product.id == productId);
            })
        })
        return of(mainMenuSections);
    }

    getBrunch(): Observable<any> {
        brunchSections.forEach(section => {
            section['products'] = section.productIds.map(productId => {
                return products.find(product => product.id == productId);
            })
        })
        return of(brunchSections);
    }

    getBreak(): Observable<any> {
        breakSections.forEach(section => {
            section['products'] = section.productIds.map(productId => {
                return products.find(product => product.id == productId);
            })
        })
        return of(breakSections);
    }

    getDrinks(): Observable<any> {
        drinkSections.forEach(section => {
            section['products'] = section.productIds.map(productId => {
                return products.find(product => product.id == productId);
            })
        })
        return of(drinkSections);
    }
}